export type ResponseEntityId = number
export type NodeElementsId = string
export type FileId = string

export interface FilterWithPagination {
	paginationParams?: [page: number, limit: number]
}

export interface ResponseEntitiesList<T> {
	data: T[]
	total: number
}

export interface AsyncThunkActionResponse {
	meta: { requestStatus: 'rejected' | 'fulfilled' }
}

export interface ResponseWithError {
	error: Error
}

interface Error {
	status: number
	data: ErrorData
}

interface ErrorData {
	message: string
	tariffRestriction?: boolean
}

export enum Constants {
	YEAR_LIST_LIMIT = 100,
	API_BASE_URL = 'https://edu.znaviki.ru/api',
	BASE_URL = 'https://edu.znaviki.ru',
	PASSWORD_MIN_LENGTH = 8,
	SUPPORT_EMAIL = 'support@znaviki.ru',
	REPEAT_CODE_TIMEOUT_SECONDS = 30,
	// API_BASE_URL = 'https://dev.znaviki.ru/api',
	// BASE_URL = 'https://dev.znaviki.ru'
}

export enum StorageKeys {
	REGISTRATION_DATA = 'REGISTRATION_DATA',
	PASSWORD_RECOVERY_DATA = 'PASSWORD_RECOVERY_DATA',
	REGISTRATION_STEP = 'REGISTRATION_STEP',
	PASSWORD_RECOVERY_STEP = 'PASSWORD_RECOVERY_STEP',
	TOKEN = 'TOKEN',
	CURRENT_SCHOOL_ID = 'CURRENT_SCHOOL_ID',
	CURRENT_ROLE = 'CURRENT_ROLE',
	CURRENT_COURSES_ID_LIST = 'CURRENT_COURSES_ID_LIST',
	TARIFF_SOON_TO_EXPIRE_MODAL_SHOWN = 'TARIFF_SOON_TO_EXPIRE_MODAL_SHOWN'
}

export enum ValidationErrorMessages {
	CONNECTION_LOST = 'Потеряно соединение с интернетом',
	SOMETHING_WENT_WRONG = 'Что то пошло не так',
	EMPTY = 'Заполните поле',
	INCORRECT = 'Неверный формат',
	DATEPICKER_INCORRECT = 'Заполните информацию полностью',
	DATEPICKER_END_BIG_THEN_START = 'Дата окончания должна быть больше, чем дата начала',
	DATEPICKER_DATE_CANNOTBE_LESS_THAN_TODAY = 'Дата начала не может быть меньше, чем сегодня',
	ATTEMPT_COUNT_INCORRECT = 'Введите число от 5 до 10',
	PASSWORD_NOT_EQUALS = 'Пароли не совпадают',
	CHOOSE_AT_LEAST_ONE = 'Выберите хотя бы один вариант',
	CHOOSE_AT_LEAST_TWO = 'Выберите хотя бы два варианта',
	PICK_CORRECT_ANSWERS = 'Выберите хотя бы один правильный вариант ответа для каждого из вопросов',
	PASSING_SCORE_INCORRECT = 'Количество баллов должно быть больше нуля и меньше количества вопросов',
	PASSING_SCORE_TASK_INCORRECT = 'Количество баллов в задании должно быть от 0 до 10',
	TARIFF_CAPACITY_OVERFLOW = 'Объема загружаемых файлов не хватает для загрузки текущего файла',
	UPLOAD_FILES_COUNT = 'Максмальное число загружаемых файлов'
}
export enum ValidationSuccessMessages {
	SUCCESS_CHANGE = 'Данные успешно сохранены',
	COPY_COURSE_SUCCESS = 'Курс успешно скопирован и перемещен в черновики',
	CONNECTION_RESTORED = 'Соединение с интернетом восстановлено'
}
export enum PatternsType {
	TRIM_STRING = 'TRIM_STRING',
	MAX_NUMBER = 'MAX_NUMBER'
}
export enum SortDirections {
	TO_TOP = 'TO_TOP',
	TO_BOTTOM = 'TO_BOTTOM'
}
export enum SchoolRole {
	ADMIN = 'Администратор',
	TEACHER = 'Преподаватель'
}
export enum CourseRole {
	TEACHER = 'Преподаватель',
	CURATOR = 'Куратор',
	STUDENT = 'Студент'
}

export enum Months {
	JANUARY,
	FEBRUARY,
	MARCH,
	APRIL,
	MAY,
	JUNE,
	JULY,
	AUGUST,
	SEPTEMBER,
	OCTOBER,
	NOVEMBER,
	DECEMBER
}

export const FREE_STARTER_TARIFF_NAME = 'Базовый'

export const DEFAULT_TASK_REQUEST_COUNT = 12

export const enum BreakPoint {
	TabletVerySmall = 690
}
